/**
 * @file It contains scripts for File Component.
 */
export default {
  name : "File",
  props: {
    pIsDownloadable: Boolean,
    pIsRemovable   : Boolean,
    pSize          : Number,
    pName          : String,
    pIsUploading   : Boolean
  },
  computed: {
    fileSize() {
      let size
      size = this.pSize / 1024
      if (size > 1024) {
        size = `${Math.floor(size / 1024)} MB`
      } else {
        size = `${Math.floor(size)} KB`
      }
      return size
    }
  }
}