/**
 * @file It contains scripts for input append outer Component
 */
export default {
  name : "InputAppendOuter",
  data : () => ({}),
  props: {
    pUpdateColor: String,
    pUpdate     : Boolean,
    pLoad       : Boolean,
    pSuccess    : Boolean
  }
}
