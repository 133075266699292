/**
 * @file It contains scripts for Date time picker component.
 */

export default {
  name : "DateTimePicker",
  props: {
    value: Date
  },
  data() {
    return {
      activeTab: null,
      date     : undefined,
      time     : undefined
    }
  },
  methods: {
    handleSave() {
      this.activeTab = null
      if(!this.date) {
        this.date = this.$moment(new Date()).format("YYYY-MM-DD")
      }
      if(!this.time) {
        this.time = "00:00"
      }
      this.$emit("input", this.dateTime)
      this.$emit("save")
    },
    handleCancel() {
      this.activeTab = null
      this.$emit("close")
    }
  },
  computed: {
    dateTime() {
      if(this.date && this.time) {
        return new Date(this.date + "T" + this.time)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler  : function(newValue) {
        if(!newValue) {
          this.date = undefined
          this.time = undefined
        } else {
          this.date = this.$moment(newValue).format("YYYY-MM-DD")
          this.time = this.$moment(newValue).format("HH:mm")
        }
      }
    }
  }
}
