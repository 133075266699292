import ThemisInputAppend from "@/components/shared/input-append"
import ThemisInputAppendOuter from "@/components/shared/input-append-outer"
import ThemisDecision from "@/components/shared/decision"
import ThemisDateTimePicker from "@/components/shared/date-time-picker"
import ThemisFile from "@/components/shared/file"
import ThemisLogs from "@/components/shared/logs"
import ThemisInput from "@/components/shared/input"
import ThemisReadOnly from "@/components/shared/read-only"
import ThemisIssueDocuments from "@/components/issue/documents"
import IssueFields from "@/components/issue/fields"
import { compareArray, convertDaysToDuration } from "@/utils"
import { mapActions, mapGetters } from "vuex"
import { FIELD_TYPES, ISSUE_TYPES, STATUS_CLASS_MAPPING, DEFAULT_DATA_RETENTION_PERIODS, DATA_RETENTION_STATUS } from "@/constants"

export default {
  name      : "IssueSidePanel",
  components: {
    ThemisInputAppend,
    ThemisInputAppendOuter,
    ThemisDecision,
    ThemisFile,
    ThemisLogs,
    ThemisDateTimePicker,
    ThemisIssueDocuments,
    ThemisInput,
    ThemisReadOnly,
    IssueFields
  },
  data: () => ({
    localIssue                        : undefined,
    canViewDomain                     : false,
    canUpdateDomain                   : false,
    canViewAssignee                   : false,
    canUpdateAssignee                 : false,
    canViewReceivedOn                 : false,
    canUpdateReceivedOn               : false,
    canViewAcknowledgedOn             : false,
    canUpdateAcknowledgedOn           : false,
    canViewStatus                     : false,
    canUpdateStatus                   : false,
    canViewLabels                     : false,
    canUpdateLabels                   : false,
    isAssigneeInputFocussed           : false,
    isDomainInputFocussed             : false,
    isLabelsInputFocussed             : false,
    isReceivedOnInputFocussed         : false,
    isAcknowledgedOnInputFocussed     : false,
    isCloseIssueDialogOpen            : false,
    newLabel                          : undefined,
    isChangeDomainDialogOpen          : false,
    isReceivedOnDateTimePickerOpen    : false,
    isAcknowledgedOnDateTimePickerOpen: false,
    isIssueFieldsExpanded             : true,
    selectedDataRetentionPeriod       : null
  }),
  computed: {
    ...mapGetters({
      loggedInUser                      : "auth/loggedInUser",
      accesses                          : "accesses/accesses",
      languages                         : "languages/languages",
      usersIncludingDeletedUsers        : "users/usersIncludingDeletedUsers",
      groups                            : "groups/groups",
      groupsUsers                       : "groups/groupsUsers",
      domains                           : "domains/domains",
      configurations                    : "configurations/configurations",
      issues                            : "issues/issues",
      isUpdatingAssignee                : "issues/isUpdatingAssignee",
      isAssigneeUpdated                 : "issues/isAssigneeUpdated",
      isUpdatingDomain                  : "issues/isUpdatingDomain",
      isDomainUpdated                   : "issues/isDomainUpdated",
      isUpdatingStatus                  : "issues/isUpdatingStatus",
      isStatusUpdated                   : "issues/isStatusUpdated",
      resolutions                       : "issueResolutions/issueResolutions",
      statuses                          : "issueStatuses/issueStatuses",
      labels                            : "labels/labels",
      isUpdatingLabels                  : "issues/isUpdatingLabels",
      isLabelsUpdated                   : "issues/isLabelsUpdated",
      isReceivedOnUpdated               : "issues/isReceivedAtUpdated",
      isUpdatingReceivedOn              : "issues/isUpdatingReceivedAt",
      isAcknowledgedOnUpdated           : "issues/isAcknowledgedAtUpdated",
      isUpdatingAcknowledgedOn          : "issues/isUpdatingAcknowledgedAt",
      issueViewPolicies                 : "accessControl/issueViewPolicies",
      issueUpdatePolicies               : "accessControl/issueUpdatePolicies",
      reports                           : "reports/reports",
      issueFields                       : "issueFields/issueFields",
      fieldsV2                          : "fields/fieldsV2",
      optionListItems                   : "optionListItems/optionListItems",
      issueFieldValues                  : "issueFieldValues/issueFieldValues",
      isUpdatingIssueFieldValue         : "issueFieldValues/isUpdatingIssueFieldValue",
      isIssueFieldValueUpdated          : "issueFieldValues/isIssueFieldValueUpdated",
      issueUpdateIssueFieldValuesPolices: "accessControl/issueUpdateIssueFieldValuesPolices",
      issueTypes                        : "issueTypes/issueTypes",
      dataRetentionPeriods              : "dataRetentionPeriods/dataRetentionPeriods"
    }),
    dataRetainedUntilValueForDisplaying() {
      return this.localIssue.dataRetainedUntil === null
        ? this.$t("691")
        : this.$moment(this.localIssue.dataRetainedUntil).format("D MMMM YYYY")
    },
    domainsMap() {
      const domainsMap = new Object()
      for (const domain of this.domains) {
        domainsMap[domain.id] = domain
      }
      return domainsMap
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    resolutionsMap() {
      const resolutionsMap = new Object()
      for (const resolution of this.resolutions) {
        resolutionsMap[resolution.id] = resolution
      }
      return resolutionsMap
    },
    statusesMap() {
      const statusesMap = new Object()
      for (const status of this.statuses) {
        statusesMap[status.id] = status
      }
      return statusesMap
    },
    usersMap() {
      const usersMap = new Object()
      for (const user of this.usersIncludingDeletedUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    groupsMap() {
      const groupsMap = new Object()
      for (const group of this.groups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    issue() {
      return this.issues?.find(issue => issue.id === +this.$route.params.id)
    },
    report() {
      return this.reports?.find(report => report.issueId === this.issue.id)
    },
    activeDomains() {
      const activeDomains            = this.domains?.filter(domain => domain.archived === false)
      const isIssueAssignedToADomain = !!this.localIssue.domainId

      if (isIssueAssignedToADomain) {
        const domain = this.domainsMap[this.localIssue.domainId]
        activeDomains.push(domain)
      }
      return activeDomains
    },
    usersWithUnassignedOption() {
      const usersWithDetails = []
      const isIssueAssigned  = !!this.localIssue.assigneeId
      if (isIssueAssigned) {
        const user                          = this.usersMap[this.localIssue.assigneeId]
        const assignedUserNoLongerHasAccess = !this.usersWithAllLevelAccess.includes(this.localIssue.assigneeId)
        const assignedUserIsDisabled        = user ? !user.enabled : false
        if (assignedUserNoLongerHasAccess || assignedUserIsDisabled) {
          usersWithDetails.push(user)
        }
      }
      for (const userWithAccess of this.usersWithAllLevelAccess) {
        const user = this.usersMap[userWithAccess]
        if (user?.enabled) {
          usersWithDetails.push(user)
        }
      }
      return [{
        id  : 0,
        name: this.$t("372")
      }, ...usersWithDetails]
    },
    status() {
      return this.statuses.find(status => status.id === this.localIssue.statusId)
    },
    resolution() {
      return this.resolutions.find(resolution => resolution.id === this.issue?.resolutionId)
    },
    isAssigneeChanged() {
      return this.issue?.assigneeId !== this.localIssue?.assigneeId
    },
    isDomainChanged() {
      return this.issue?.domainId !== this.localIssue?.domainId
    },
    isReceivedOnChanged() {
      return new Date(this.issue?.receivedAt).getTime() !== new Date(this.localIssue?.receivedAt).getTime()
    },
    isAcknowledgedOnChanged() {
      return new Date(this.issue?.acknowledgedAt).getTime() !== new Date(this.localIssue?.acknowledgedAt).getTime()
    },
    isStatusClosed() {
      return this.issue?.statusId === this.$CONSTANTS.ISSUE_STATUS.CLOSED
    },
    getStatusClass() {
      return STATUS_CLASS_MAPPING[this.issue?.statusId]
    },
    isStatusNew() {
      return this.issue?.statusId === this.$CONSTANTS.ISSUE_STATUS.NEW
    },
    isLabelsChanged() {
      return !compareArray(this.issue?.labels, this.localIssue?.labels)
    },
    labelsForSelection() {
      const labelsForSelection = [{
        header: this.$t("604")
      }]
      labelsForSelection.push(...this.labels.map(label => label.name))
      return labelsForSelection
    },
    receivedAtForDisplaying() {
      if (this.localIssue.receivedAt) {
        return this.$moment(this.localIssue.receivedAt).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    acknowledgedAtForDisplaying() {
      if (this.localIssue.acknowledgedAt) {
        return this.$moment(this.localIssue.acknowledgedAt).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    parentIssue() {
      return this.issues.find(issue => issue.id === this.issue?.parentId)
    },
    usersWithAllLevelAccess() {
      let usersWithAllLevelAccess = []
      for (const access of this.accesses) {
        if (this.issue.parentId) {
          if (access.criteria?.id === this.parentIssue.id && access.userId) {
            usersWithAllLevelAccess.push(access.userId)
          } else if (access.criteria?.id === this.parentIssue.id && access.groupId) {
            if (this.groupsUsers[access.groupId]) {
              usersWithAllLevelAccess = [...usersWithAllLevelAccess,
                ... this.groupsUsers[access.groupId]]
            }
          }
        }
        if (access.criteria?.id === this.issue?.id && access.userId) {
          usersWithAllLevelAccess.push(access.userId)
        } else if (access.criteria?.id === this.issue?.id && access.groupId) {
          if (this.groupsUsers[access.groupId]) {
            usersWithAllLevelAccess = [...usersWithAllLevelAccess,
              ... this.groupsUsers[access.groupId]]
          }
        }
      }
      usersWithAllLevelAccess = [...usersWithAllLevelAccess, ... this.usersWithDomainLevelAccess]
      return [...new Set(usersWithAllLevelAccess)]
    },
    usersWithDomainLevelAccess() {
      let usersWithDomainLevelAccess = []
      for (const access of this.accesses) {
        const issue = this.issue.parentId ? this.parentIssue : this.issue
        if (access.criteria?.domainId === issue.domainId && access.userId) {
          usersWithDomainLevelAccess.push(access.userId)
        } else if (access.criteria?.domainId === issue.domainId && access.groupId) {
          if (this.groupsUsers[access.groupId]) {
            usersWithDomainLevelAccess = [...usersWithDomainLevelAccess,
              ... this.groupsUsers[access.groupId]]
          }
        }
      }
      return [...new Set(usersWithDomainLevelAccess)]
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    issueFieldsList() {
      return [...this.issueFields].sort((firstIssueField, secondIssueField) =>
        firstIssueField.sortingOrder - secondIssueField.sortingOrder
      ).map(issueField => {
        const field = this.fieldsMap.get(issueField.fieldId)
        let fieldId, fieldType, fieldWidget, fieldLabel, optionListId
        if (field) {
          fieldId =  field.id,
          fieldType = field.type,
          fieldWidget = field.widget,
          fieldLabel = field.label,
          optionListId = field.optionListId
        }
        return {
          fieldId,
          issueFieldId: issueField.id,
          issueTypeId : issueField.issueTypeId,
          fieldType,
          fieldWidget,
          fieldLabel,
          optionListId,
          issueId     : +this.$route.params.id
        }
      })
    },
    issueUpdatePolicy() {
      return this.issueUpdatePolicies.find(issueUpdatePolicy => issueUpdatePolicy.id === +this.$route.params.id)
    },
    issueViewPolicy() {
      return this.issueViewPolicies.find(issueViewPolicy => issueViewPolicy.id === +this.$route.params.id)
    },
    isACase() {
      return this.issue.typeId === ISSUE_TYPES[0].id
    },
    issueType() {
      return this.issueTypes.find(issueType => issueType.id === this.issue.typeId)
    },
    issueTypeStatuses() {
      return this.issueType.statuses
    },
    issueStatuses() {
      const issueStatuses = []
      for (const statusId of this.issueTypeStatuses) {
        issueStatuses.push(this.statusesMap[statusId])
      }
      return issueStatuses
    },
    isAssigneeALoggedInUser() {
      return this.issue.assigneeId === this.loggedInUser.id
    },
    retentionPeriods() {
      if (this.dataRetentionPeriods.length) {
        return [...this.dataRetentionPeriods].sort((firstDataRetentionPeriod, secondRetentionPeriod)=>
          firstDataRetentionPeriod.value - secondRetentionPeriod.value)
          .map(dataRetentionPeriod => dataRetentionPeriod.value)
      }
      return DEFAULT_DATA_RETENTION_PERIODS
    },
    retentionPeriodsToDisplay() {
      const dataRetentionPeriods = []
      for (const dataRetentionOption of this.retentionPeriods) {
        const dataRetentionPeriod = convertDaysToDuration(dataRetentionOption)
        dataRetentionPeriods.push({
          days    : dataRetentionOption,
          duration: this.$tc(dataRetentionPeriod.localeValue, dataRetentionPeriod.count,
            { count: dataRetentionPeriod.count })
        })
      }
      dataRetentionPeriods.push({
        days    : 0,
        duration: "Do not clear"
      })
      return dataRetentionPeriods
    },
    isCloseButtonDisabled() {
      if (this.selectedDataRetentionPeriod === null || this.localIssue.resolutionId === null) {
        return true
      }
    }
  },
  methods: {
    ...mapActions({
      updateIssue          : "issues/updateIssue",
      notify               : "shared/notify",
      updateIssueFieldValue: "issueFieldValues/updateIssueFieldValue"
    }),
    selfAssignIssue() {
      this.localIssue.assigneeId = this.loggedInUser.id
      this.handleUpdateAssignee()
    },
    handleUpdateAssignee() {
      let assigneeId =  this.localIssue.assigneeId
      if (this.isAssigneeChanged && assigneeId !== null) {
        if (assigneeId === 0) {
          assigneeId = null
        }
        this.updateIssue({
          id: this.localIssue.id,
          assigneeId
        })
      }
    },
    handleAssigneeInputOnBlurEvent(onBlur) {
      onBlur()
      if (this.localIssue.assigneeId === null) {
        if(this.issue.assigneeId === null) {
          this.localIssue.assigneeId = 0
        } else {
          this.localIssue.assigneeId = this.issue.assigneeId
        }
      }
    },
    updateStatus(status) {
      if (status.id === this.$CONSTANTS.ISSUE_STATUS.CLOSED) {
        if (!this.report || this.report.status === this.$CONSTANTS.REPORT_STATUS.CLOSED) {
          this.isCloseIssueDialogOpen = true
        } else {
          this.notify({
            type: "error",
            text: "435",
            icon: "mdi-alert-circle"
          })
        }
      } else {
        this.updateIssue({
          id      : this.localIssue.id,
          statusId: status.id
        })
      }
    },
    removeLabel(labelToBeRemoved) {
      this.localIssue.labels = this.localIssue.labels.filter(label => label !== labelToBeRemoved)
    },
    handleUpdateReceivedAt() {
      this.isReceivedOnDateTimePickerOpen = false
      this.updateIssue({
        id        : this.localIssue.id,
        receivedAt: this.localIssue.receivedAt
      })
    },
    handleLabelInputOnFocusEvent(onFocus) {
      onFocus()
      this.isLabelsInputFocussed = true
    },
    handleLabelInputOnBlurEvent(onBlur) {
      onBlur()
      this.isLabelsInputFocussed = false
    },
    handleUpdateAcknowledgedAt() {
      this.isAcknowledgedOnDateTimePickerOpen = false
      this.updateIssue({
        id            : this.localIssue.id,
        acknowledgedAt: this.localIssue.acknowledgedAt
      })
    },
    handleUpdateDomain() {
      this.updateIssue({
        id      : this.localIssue.id,
        domainId: this.localIssue.domainId
      })
    },
    handleResetReceivedAt() {
      this.localIssue.receivedAt = this.issue.receivedAt ? new Date(this.issue.receivedAt) : null
    },
    handleResetAcknowledgedAt() {
      this.localIssue.acknowledgedAt = this.issue.acknowledgedAt ? new Date(this.issue.acknowledgedAt) : null
    },
    handleCloseAcknowledgementAtDateTimePicker() {
      this.isAcknowledgedOnDateTimePickerOpen = false
      this.handleResetAcknowledgedAt()
    },
    handleCloseReceivedAtDateTimePicker() {
      this.isReceivedOnDateTimePickerOpen = false
      this.handleResetReceivedAt()
    },
    handleReceivedOnInputOnFocusEvent(onFocus) {
      onFocus()
      this.isReceivedOnInputFocussed = true
    },
    handleReceivedOnInputOnBlurEvent(onBlur) {
      onBlur()
      this.isReceivedOnInputFocussed = false
    },
    handleAcknowledgedInputOnFocusEvent(onFocus) {
      onFocus()
      this.isAcknowledgedOnInputFocussed = true
    },
    handleAcknowledgedInputOnBlurEvent(onBlur) {
      onBlur()
      this.isAcknowledgedOnInputFocussed = false
    },
    cancelUpdateDomain() {
      this.localIssue.domainId = this.issue.domainId
    },
    handleDateConversion(value) {
      return this.$moment(value).format("D MMMM YYYY HH:mm (UTCZ)")
    },
    toggleExpandCollapseIssueFields() {
      this.isIssueFieldsExpanded = !this.isIssueFieldsExpanded
    },
    getIssueFieldValuesForAParticularIssueField(issueField) {
      return this.issueFieldValues
        .filter(issueFieldValue =>
          issueFieldValue.issueFieldId === issueField.issueFieldId &&
          issueFieldValue.issueId === +this.$route.params.id
        )
    },
    getOptionListItemsForAParticularIssueField(issueField) {
      if ([FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value]
        .includes(issueField.fieldType)) {
        const sortedOptionListItems = this.optionListItems
          .filter(optionListItem => optionListItem.optionListId === issueField.optionListId)
          .sort((optionListItem1, optionListItem2) => optionListItem1.sortingOrder - optionListItem2.sortingOrder)
          .map(optionListItem => ({
            id      : optionListItem.id,
            name    : optionListItem.name,
            parentId: optionListItem.parentId
          }))

        const isMultiple              = this.isFieldTypeMultipleOptionList(issueField)
        const options                 = this.generateNestedOptionListItems(sortedOptionListItems, isMultiple)
        const currentIssueFieldValues = this.getIssueFieldValuesForAParticularIssueField(issueField)

        for (const issueFieldValue of currentIssueFieldValues) {
          const isOptionListItemExists = sortedOptionListItems.find(option => issueFieldValue.value === option.name)
          if (!isOptionListItemExists && issueFieldValue.value) {
            options.push({ name: issueFieldValue.value })
          }
        }
        return options
      }
    },
    isFieldTypeMultipleOptionList(issueField) {
      return issueField.fieldType === FIELD_TYPES.MULTIPLE_OPTION_LIST.value
    },
    generateNestedOptionListItems(data, isMultiple) {
      const idToObject = {}
      const result     = []

      data.forEach(item => {
        idToObject[item.id] = { ...item, children: [] }
      })

      data.forEach(item => {
        const parent = idToObject[item.parentId]
        if (parent) {
          parent.disabled = !isMultiple
          parent.children.push(idToObject[item.id])
        } else {
          result.push(idToObject[item.id])
        }
      })

      return result
    },
    getIssueUpdateIssueFieldValuesPolicesForAParticularIssueField(issueField) {
      return this.issueUpdateIssueFieldValuesPolices.find(issueFieldUpdatePolicy =>
        issueFieldUpdatePolicy.id === issueField.issueId)
    },
    isOptionListCascaded(issueField) {
      const filteredOptionListItems = this.optionListItems.filter(optionList =>
        optionList.optionListId === issueField.optionListId)
      return !!filteredOptionListItems.find(optionListItem => optionListItem.parentId)?.parentId
    },
    handleUpdateIssue() {
      if (this.selectedDataRetentionPeriod === 0) {
        this.updateIssue({
          id          : this.localIssue.id,
          statusId    : this.$CONSTANTS.ISSUE_STATUS.CLOSED,
          resolutionId: this.localIssue.resolutionId
        })
      } else {
        this.updateIssue({
          id                 : this.localIssue.id,
          statusId           : this.$CONSTANTS.ISSUE_STATUS.CLOSED,
          resolutionId       : this.localIssue.resolutionId,
          dataRetentionStatus: DATA_RETENTION_STATUS.SCHEDULED,
          dataRetainedUntil  : this.$moment().add(this.selectedDataRetentionPeriod, "days").format("YYYY-MM-DD")
        })
      }
    },
    handleCancelUpdateIssue() {
      this.isCloseIssueDialogOpen      = false
      this.selectedDataRetentionPeriod = null
      this.localIssue.resolutionId     = null
    }
  },
  watch: {
    issue: {
      immediate: true,
      handler  : function(newValue) {
        if(newValue) {
          const isLocalIssueNotSet          = !this.localIssue
          const isLocalIssueSetButDifferent = !isLocalIssueNotSet && (this.localIssue.id !== newValue.id)
          const hasStatusChanged            = !isLocalIssueNotSet && this.localIssue.statusId !== newValue.statusId
          if (isLocalIssueNotSet || isLocalIssueSetButDifferent) {
            this.localIssue = {
              ...newValue,
              receivedAt    : newValue.receivedAt ? new Date(newValue.receivedAt) : null,
              acknowledgedAt: newValue.acknowledgedAt ? new Date(newValue.acknowledgedAt) : null,
              assigneeId    : newValue.assigneeId ? newValue.assigneeId : 0
            }
          } else if (hasStatusChanged) {
            this.localIssue.statusId = newValue.statusId
          }
        }
      }
    },
    issueViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.select) {
          this.canViewAssignee       = newValue.select.includes("assigneeId")
          this.canViewDomain         = newValue.select.includes("domainId")
          this.canViewAcknowledgedOn = newValue.select.includes("acknowledgedAt")
          this.canViewLabels         = newValue.select.includes("labels")
          this.canViewReceivedOn     = newValue.select.includes("receivedAt")
          this.canViewStatus         = newValue.select.includes("statusId")
          this.canViewCreatedOn      = newValue.select.includes("createdAt")
          this.canViewUpdatedOn      = newValue.select.includes("updatedAt")
          this.canViewClosedOn       = newValue.select.includes("closedAt")
        }
      }
    },
    issueUpdatePolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.set) {
          this.canUpdateSummary        = newValue.set.summary !== undefined
          this.canUpdateDescription    = newValue.set.description !== undefined
          this.canUpdateAssignee       = newValue.set.assigneeId !== undefined
          this.canUpdateDomain         = newValue.set.domainId !== undefined
          this.canUpdateAcknowledgedOn = newValue.set.acknowledgedAt !== undefined
          this.canUpdateLabels         = newValue.set.labels !== undefined
          this.canUpdateReceivedOn     = newValue.set.receivedAt !== undefined
          this.canUpdateStatus         = newValue.set.statusId !== undefined
        } else {
          this.canUpdateSummary        = undefined
          this.canUpdateDescription    = undefined
          this.canUpdateAssignee       = undefined
          this.canUpdateDomain         = undefined
          this.canUpdateAcknowledgedOn = undefined
          this.canUpdateLabels         = undefined
          this.canUpdateReceivedOn     = undefined
          this.canUpdateStatus         = undefined
        }
      }
    },
    isStatusUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue && this.localIssue.statusId === this.$CONSTANTS.ISSUE_STATUS.CLOSED) {
          this.notify({
            type      : "success",
            text      : "436",
            parameters: {
              id: this.localIssue.id
            }
          })
          this.isCloseIssueDialogOpen = false
        }
      }
    },
    isUpdatingStatus: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.CLOSE_ISSUE.pActions[1].buttonProps.loading = newValue
      }
    },
    isCloseButtonDisabled: {
      immediate: true,
      handler  : function(newValue) {
        this.$DECISIONS.CLOSE_ISSUE.pActions[1].buttonProps.disabled = newValue
      }
    },
    isLabelsInputFocussed: {
      handler: function(newValue) {
        if (!newValue) {
          if (this.isLabelsChanged) {
            this.updateIssue({
              id    : this.localIssue.id,
              labels: this.localIssue.labels
            })
          }
        }
      }
    },
    isUpdatingDomain: {
      handler: function(newValue) {
        this.$DECISIONS.CHANGE_ISSUE_DOMAIN.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.CHANGE_ISSUE_DOMAIN.pActions[0].buttonProps.disabled = newValue
        if (!newValue) {
          this.isChangeDomainDialogOpen = false
        }
      }
    },
    isDomainUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "666",
            parameters: {
              name: this.domainsMap[this.localIssue.domainId].name
            }
          })
          if (!this.usersWithDomainLevelAccess.includes(this.loggedInUser.id)) {
            this.$router.push({ name: "issues" })
          }
        }
      }
    }
  }
}
