/**
 * @file It contains scripts for input append Component
 */
export default {
  name : "InputAppend",
  data : () => ({}),
  props: {
    pEdit      : Boolean,
    pReset     : Boolean,
    pResetColor: String
  }
}
